<template>
  <Layout>
    <div class="ppcontainer regcontainer">
      <Header
        :back="true"
        regulation="pipl"
      />
      <h1
        class="title"
        :dir="dir"
      >
        九游渠道权限说明
      </h1>
      <h1 :dir="dir">
        为了保证您正常的游戏体验，我们可能向您申请获取以下权限：
      </h1>
      <div class="text scrollable">
        <table>
          <thead>
            <tr>
              <th>调用的设备权限</th>
              <th>权限对应的业务功能</th>
              <th>调用权限目的</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>储存权限</td>
              <td>下载和安装游戏更新内容</td>
              <td>拒绝授权将可能无法正常进行游戏</td>
            </tr>
            <tr>
              <td>电话权限</td>
              <td>
                获取设备标识（AndroidID、mac），生成帐号、保存和恢复游戏数据
              </td>
              <td>为了生成帐号并保存/恢复游戏数据</td>
            </tr>
            <tr>
              <td>后台运行权限</td>
              <td>
                允许程序在手机屏幕关闭后后台进程仍然运行，防止游戏进程断开
              </td>
              <td>确保返回游戏时无需重启游戏</td>
            </tr>
            <tr>
              <td>网络状态权限</td>
              <td>获取当前WiFi接入状态及WLAN热点信息</td>
              <td>确保游戏在联网情况下才可正常运行</td>
            </tr>
            <tr>
              <td>获取应用安装列表权限</td>
              <td>收集应用程序性能、崩溃等情况，为了预防恶意程序及安全运营</td>
              <td>必须收集应用安装列表以确保安全运营，避免恶意程序</td>
            </tr>
            <tr>
              <td>麦克风权限</td>
              <td>提供语音服务功能</td>
              <td>访问麦克风以提供语音服务功能</td>
            </tr>
            <tr>
              <td> READ_CALENDAR 读取日历 </td>
              <td>读取您系统中的日历活动</td>
              <td>用于游戏、直播、节目等预约及订阅时新建日程提醒</td>
            </tr>
            <tr>
              <td> WRITE_CALENDAR 编辑日历 </td>
              <td>添加或修改系统中的日历活动</td>
              <td />
            </tr>
            <tr>
              <td> RECORD_AUDIO 录音 </td>
              <td>使用麦克风录制音频</td>
              <td>
                用于九游录屏时录音或通过语音识别技术制作字幕；用于九游直播连麦等
              </td>
            </tr>
            <tr>
              <td> READ_PHONE_STATE 读取电话状态 </td>
              <td>
                提供读取手机设备标识等信息，请您放心该权限无法监听、获取您的任何通话内容与信息
              </td>
              <td>
                读取设备通话状态和识别码，识别手机设备ID，保证保障软件使用安全顺畅；协助识别用户，更好地解决用户问题
              </td>
            </tr>
            <tr>
              <td> READ_EXTERNAL_STORAGE 读取外置存储器 </td>
              <td>提供读取手机储存空间内数据的功能</td>
              <td>
                下载、安装、更新并管理你的游戏，个性化推荐，同时可以通过九游APP顺利完成微信、QQ等第三方账号登录
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </Layout>
</template>

<script>
import Header from '@components/Header.vue'

export default {
  metaInfo() {
    return {
      title: 'Outfit7 Compliance module',
      meta: [
        {
          key: 'description',
          name: 'description',
          content: 'Outfit7 Compliance module',
        },
      ],
    }
  },
  components: {
    Header,

  },
  data() {
    return {
      content: {},
      orientation: '',
      lang: '',
    }
  },
  created() {
    this.content = this.$compliance.getLocalisedContent()
  },
  mounted() {
    this.orientation = this.$compliance.screenOrientation
    this.lang = this.$compliance.lang
    this.$compliance.forceRedraw()
  },
}
</script>

<style lang="scss">
.ppcontainer {
  position: relative;
  padding: 10px 0 10px 0;
  background: #fff;
  width: 100%;

  .logo {
    width: 20%;
    height: 40px;
    max-width: 130px;
    margin: 20px auto 0 auto;
    background-image: url('../../../assets/images/logo.jpg');
    background-repeat: no-repeat;
    background-size: contain;
  }
  .header {
    width: calc(100% - 60px);
    height: auto;
    margin: 0 30px;
  }
  .v-lazy-image {
    filter: blur(10px);
    transition: filter 0.7s;
  }
  .v-lazy-image-loaded {
    filter: blur(0);
  }
  .title {
    text-transform: uppercase;
    color: #4f5c68;
    font-size: 1.8em;
    font-weight: 800;
    line-height: 1em;
    text-align: center;
    width: 100%;
    display: inline-block;
    margin: 10px auto 10px auto;

    @media screen and (min-width: 768px) {
      font-size: 3.2em;
    }
    @media screen and (min-width: 1024px) {
      font-size: 5em;
    }
  }
  .text {
    padding: 0 10px 20px 10px;
    font-size: 1em;
    color: #4c5d6a;
    text-align: justify;
    &.noheader {
      margin: 0;
    }
    &.notitle {
      margin: 20px 0 0 0;
    }
    span {
      color: #688194;
      text-decoration: underline;
    }
    @media screen and (min-width: 768px) {
      font-size: 1.8em;
      padding: 0 10px 20px 10px;
    }
    @media screen and (min-width: 1024px) {
      font-size: 2.1em;
    }
  }
  .scrollable {
    overflow-x: scroll;
    td {
      min-width: 240px;
    }
  }
}
.absolutefooter .tcf20container {
  height: 100%;
}
</style>
